import React, { useContext } from 'react';
import { Typography, Button } from '@mui/material';
import useStyle from './style';
import Slider from '@mui/material/Slider';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getParametersAction, getTableApartmentsAction } from '../actions/apartments_action';
import { GlobalContext } from '../App';
import { getDataByParametersAction } from '../actions/apartments_action';
import { useSearchParams } from 'react-router-dom';
import { getBuildingsAction } from '../actions/buildings_action';
import { getBuildingLevelsAction } from '../actions/levels_action';
import { getApartmentsForLevelAction } from '../actions/apartments_action';

const roomTypes = [
    {
        name: 'Garsonjera',
        value: 0,
        dbName: 'num_of_studio'
    },
    {
        name: 'Jednosoban',
        value: 1,
        dbName: 'num_of_one_room'
    },
    {
        name: 'Dvosoban',
        value: 2,
        dbName: 'num_of_two_rooms'
    },
    {
        name: 'Trosoban',
        value: 3,
        dbName: 'num_of_three_rooms'
    }
];

const SidePanel = () => {

    const classes = useStyle();
    const dispatch = useDispatch();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    const { currentDisplay, setCurrentDisplay, ls_parameters, set_ls_parameters } = useContext(GlobalContext);

    const [searchParams, setSearchParams] = useSearchParams();
    const buildingId = searchParams.get('buildingId');
    const levelId = searchParams.get('levelId');

    const getParametersSelector = useSelector(state => state.parametersState);
    const { parameters } = getParametersSelector;

    const buildingLevelsSelector = useSelector(state => state.buildingLevelsState);
    const { buildingLevelsData } = buildingLevelsSelector;

    const apartmentsForLevelSelector = useSelector(state => state.apartmentsForLevelState);
    const { apartmentsForLevel } = apartmentsForLevelSelector;

    const getBuildingsSelector = useSelector(state => state.getBuildingsState);
    const { getBuildingsData } = getBuildingsSelector;

    const tableApartmentsSelector = useSelector(state => state.tableApartmentsState);
    const { tableApartmentsData } = tableApartmentsSelector;

    const [firstFetch, setFirstFetch] = React.useState(false);

    const [areaMinMax, setAreaMinMax] = React.useState([0, 100]);

    const [levelMinMax, setLevelMinMax] = React.useState([0, 100]);

    const [priceMinMax, setPriceMinMax] = React.useState([0, 100]);

    const [numOfRooms, setNumOfRooms] = React.useState(-1);

    React.useEffect(() => {
        dispatch(getParametersAction());
    }, []);

    React.useEffect(() => {
        if (typeof parameters !== 'undefined') {
            if (parameters.success) {
                const params = parameters.data;
                setAreaMinMax([params.minArea, params.maxArea]);
                setLevelMinMax([params.minLevelNum, params.maxLevelNum]);
                setPriceMinMax([params.minPrice, params.maxPrice]);

                if (JSON.stringify(ls_parameters[0]) === JSON.stringify([0, 100])) {
                    const allParams = [[params.minArea, params.maxArea], [params.minLevelNum, params.maxLevelNum], [params.minPrice, params.maxPrice], -1];
                    set_ls_parameters(allParams);
                    localStorage.setItem('parameters', JSON.stringify(allParams));
                    setFirstFetch(true);
                }
            }
        }
    }, [parameters]);

    const handleAreaChange = (event, newValue) => {
        const params = JSON.parse(localStorage.getItem('parameters'));
        params[0] = newValue;
        set_ls_parameters(params);
        localStorage.setItem('parameters', JSON.stringify(params));
    };

    const handleLevelsChange = (event, newValue) => {
        const params = JSON.parse(localStorage.getItem('parameters'));
        params[1] = newValue;
        set_ls_parameters(params);
        localStorage.setItem('parameters', JSON.stringify(params));
    };

    const handlePriceChange = (event, newValue) => {
        const params = JSON.parse(localStorage.getItem('parameters'));
        params[2] = newValue;
        set_ls_parameters(params);
        localStorage.setItem('parameters', JSON.stringify(params));
    };

    const handleNumOfRoomsChange = (newValue) => {
        setNumOfRooms(newValue);
        const params = JSON.parse(localStorage.getItem('parameters'));
        params[3] = newValue;
        set_ls_parameters(params);
        localStorage.setItem('parameters', JSON.stringify(params));
    };

    React.useEffect(() => {
        if (JSON.stringify(ls_parameters[0]) !== JSON.stringify([0, 100])) {
            handleGetDataByParameters();
        }
    }, [numOfRooms, currentDisplay]);

    React.useEffect(() => {
        if (firstFetch) handleGetDataByParameters();
    }, [firstFetch]);

    const handleGetDataByParameters = () => {

        const lsAreaValues = ls_parameters[0];
        const lsLevelValues = ls_parameters[1];
        const lsPriceValues = ls_parameters[2];
        const lsNumOfRooms = ls_parameters[3];

        switch (currentDisplay) {
            case 0:
                dispatch(getDataByParametersAction({
                    parameters: [lsAreaValues, lsLevelValues, lsPriceValues, lsNumOfRooms],
                    dataType: currentDisplay
                }));
                break;
            case 1:
                dispatch(getDataByParametersAction({
                    parameters: [lsAreaValues, lsLevelValues, lsPriceValues, lsNumOfRooms],
                    dataType: currentDisplay,
                    buildingId
                }));
                break;
            case 2:
                dispatch(getDataByParametersAction({
                    parameters: [lsAreaValues, lsLevelValues, lsPriceValues, lsNumOfRooms],
                    dataType: currentDisplay,
                    buildingId,
                    levelId
                }));
                break;
            case 4:
                dispatch(getDataByParametersAction({
                    parameters: [lsAreaValues, lsLevelValues, lsPriceValues, lsNumOfRooms],
                    dataType: currentDisplay
                }));
                break;
            case 3: return;
            default: return;
        };
    };

    const handleResetParams = () => {
        const params = parameters.data;
        setAreaMinMax([params.minArea, params.maxArea]);
        setLevelMinMax([params.minLevelNum, params.maxLevelNum]);
        setPriceMinMax([params.minPrice, params.maxPrice]);
        setNumOfRooms(-1);
        set_ls_parameters([[params.minArea, params.maxArea], [params.minLevelNum, params.maxLevelNum], [params.minPrice, params.maxPrice], -1]);
        localStorage.setItem('parameters', JSON.stringify([[params.minArea, params.maxArea], [params.minLevelNum, params.maxLevelNum], [params.minPrice, params.maxPrice], -1]));
        switch (currentDisplay) {
            case 0:
                dispatch(getBuildingsAction());
                break;
            case 1:
                dispatch(getBuildingLevelsAction(buildingId));
                break;
            case 2:
                dispatch(getApartmentsForLevelAction(buildingId, levelId));
                break;
            case 4:
                dispatch(getTableApartmentsAction());
                break;
            default: return;
        }
    };

    const getRoomTypes = () => {
        return (
            roomTypes.map((room, index) => {
                let flag;
                switch (currentDisplay) {
                    case 0:
                        flag = +getBuildingsData?.types[room.dbName] > 0;
                        break;
                    case 1:
                        flag = +buildingLevelsData?.types[room.dbName] > 0;
                        break;
                    case 2:
                        flag = +apartmentsForLevel?.types[room.dbName] > 0;
                        break;
                    case 4:
                        flag = +tableApartmentsData?.types[room.dbName] > 0;
                        break;
                    default:
                        flag = +getBuildingsData?.types[room.dbName] > 0;
                }
                if (flag) {
                    return (
                        <Button
                            key={index}
                            disableElevation
                            disabled={currentDisplay === 3}
                            style={{
                                backgroundColor:
                                    currentDisplay !== 3
                                        ?
                                        ls_parameters[3] === room.value
                                            ?
                                            '#f79800'
                                            :
                                            '#0F79A1'
                                        :
                                        '#0F79A1'
                            }}
                            size='small'
                            onClick={() => {
                                handleNumOfRoomsChange(room.value);
                            }}
                            variant='contained'>
                            {room.name}
                        </Button>
                    )
                }
            })
        )
    };

    return (
        <div className={classes.sidePanelDiv}>
            <div className={classes.sliders}>
                <div className={classes.singleSlider} >
                    <Slider
                        disabled={!parameters?.success || currentDisplay === 3}
                        min={+areaMinMax[0]}
                        max={+areaMinMax[1]}
                        className={classes.sliderElement}
                        size='small'
                        disableSwap
                        style={{ color: currentDisplay === 3 ? 'gray' : '#f79800' }}
                        valueLabelDisplay="on"
                        valueLabelFormat={(value) => {
                            return Math.round(value) + ' ㎡';
                        }}
                        value={ls_parameters[0]}
                        onChange={handleAreaChange}
                        onChangeCommitted={handleGetDataByParameters}
                    />
                    <Typography variant='subtitle1' style={{ marginTop: isMobile ? '-15px' : '0px' }} >
                        Površina apartmana ( &#13217; )
                    </Typography>
                </div>
                {
                    currentDisplay !== 2
                        ?
                        <div className={classes.singleSlider}>
                            <Slider
                                className={classes.sliderElement}
                                size='small'
                                disableSwap
                                style={{ color: currentDisplay === 3 || currentDisplay === 2 ? 'gray' : '#f79800' }}
                                disabled={(!parameters?.success || currentDisplay === 3 || currentDisplay === 2)}
                                min={levelMinMax[0]}
                                max={levelMinMax[1]}
                                valueLabelDisplay="on"
                                value={ls_parameters[1]}
                                onChange={handleLevelsChange}
                                onChangeCommitted={handleGetDataByParameters}
                            />
                            <Typography variant='subtitle1' style={{ marginTop: isMobile ? '-15px' : '0px' }}>
                                Sprat
                            </Typography>
                        </div>
                        :
                        null
                }
                <div className={classes.singleSlider}>
                    <Slider
                        className={classes.sliderElement}
                        size='small'
                        disableSwap
                        style={{ color: currentDisplay === 3 ? 'gray' : '#f79800' }}
                        disabled={!parameters?.success || currentDisplay === 3}
                        min={+priceMinMax[0]}
                        max={+priceMinMax[1]}
                        valueLabelDisplay="on"
                        valueLabelFormat={(value) => {
                            return Math.round(value / 1000) * 1000 + ' €';
                        }}
                        value={ls_parameters[2]}
                        onChange={handlePriceChange}
                        onChangeCommitted={handleGetDataByParameters}
                    />
                    <Typography variant='subtitle1' style={{ marginTop: isMobile ? '-15px' : '0px' }}>
                        Cijena
                    </Typography>
                </div>
                <div className={classes.singleSliderRooms}>
                    <div className={classes.roomsDiv}>
                        <Button
                            disableElevation
                            disabled={currentDisplay === 3}
                            style={{
                                backgroundColor:
                                    currentDisplay !== 3
                                        ?
                                        ls_parameters[3] === -1
                                            ?
                                            '#f79800'
                                            :
                                            '#0F79A1'
                                        :
                                        '#0F79A1'
                            }}
                            size='small'
                            onClick={() => {
                                handleNumOfRoomsChange(-1);
                            }}
                            variant='contained'>
                            Sve
                        </Button>
                        {
                            getRoomTypes()
                        }
                    </div>
                    <Typography variant='subtitle1' style={{ marginTop: '5px' }}>
                        Tip stana
                    </Typography>
                </div>
                <div className={classes.singleSlider} style={{ marginTop: '-15px' }}>
                    <Button
                        disableElevation
                        onClick={handleResetParams}
                        variant='contained'
                        color='secondary'>
                        Resetuj
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default SidePanel;