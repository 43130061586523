import React from 'react';
import { Typography, CssBaseline, AppBar, Toolbar, IconButton, Button, Box, Drawer } from '@mui/material';
import useStyles from './style';
import logo from '../assets/photos/logo.png';
import { useMediaQuery, useTheme } from '@material-ui/core';
import MenuIcon from '@mui/icons-material/Menu';
import { useContext } from 'react';
import { GlobalContext } from '../App';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getHeaderDataAction } from '../actions/admins_action';

const NavigationBar = () => {

    const { currentDisplay, setCurrentDisplay } = useContext(GlobalContext);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();

    const [header, setHeader] = React.useState({
        phoneNumber: '',
        emailAddress: ''
    });

    const [showModal, setShowModal] = React.useState(false);

    const headerDataSelector = useSelector(state => state.headerDataState);
    const { headerData } = headerDataSelector;

    React.useEffect(() => {
        dispatch(getHeaderDataAction());
    }, []);

    React.useEffect(() => {
        if (typeof headerData !== 'undefined') {
            if (headerData.success) {
                delete headerData.success;
                document.querySelector('meta[name="description"]').setAttribute("content", headerData.pageDescription);
                document.title = headerData.pageTitle;
                setHeader({ ...headerData });
            }
        };
    }, [headerData]);

    return (
        <>
            <CssBaseline />
            <AppBar
                elevation={0}
                position="static"
                className={classes.navBar}
                style={{ backgroundColor: 'white' }}>
                <Toolbar className={classes.navToolBar}>
                    <div className={classes.logoAndOptions}>
                        <img
                            src={`${process.env.REACT_APP_UPLOADS_PATH}/logo/logo.png`}
                            alt="Logo"
                            className={classes.logo}
                            width={319}
                            height={94}
                            onClick={() => {
                                navigate('/');
                                localStorage.setItem('currentDisplay', JSON.stringify(0));
                                setCurrentDisplay(0);
                            }} />
                        <div className={classes.options}>
                            <Typography
                                onClick={() => {
                                    navigate('/');
                                    localStorage.setItem('currentDisplay', JSON.stringify(0));
                                    setCurrentDisplay(0);
                                }}
                                variant='h6'
                                className={classes.singleOption}>
                                Kompleks
                            </Typography>
                            <Typography
                                onClick={() => {
                                    navigate('/');
                                    localStorage.setItem('currentDisplay', JSON.stringify(4));
                                    setCurrentDisplay(4);
                                }}
                                variant='h6'
                                className={classes.singleOption}>
                                Tabela kompleksa
                            </Typography>
                        </div>
                    </div>

                    <div className={classes.phoneDesktop}>
                        <Typography variant='h5'>
                            {header.phoneNumber}
                        </Typography>
                        <Typography variant='h5'>
                            {header.emailAddress}
                        </Typography>
                    </div>

                    <div className={classes.mobileLogoAndMenu}>
                        <div className={classes.logoMobile}>
                            <img
                                src={`${process.env.REACT_APP_UPLOADS_PATH}/logo/logo.png`}
                                alt="Logo"
                                width={200}
                                //height={94}
                                onClick={() => {
                                    navigate('/');
                                    localStorage.setItem('currentDisplay', JSON.stringify(0));
                                    setCurrentDisplay(0);
                                }}
                                style={{ marginTop: '10px',marginLeft  :'10px', filter: 'brightness(0) invert(1)' }} />
                        </div>
                        <IconButton size='large' onClick={() => { setShowModal(true); }}>
                            <MenuIcon style={{ fontSize: '50px', color: 'white' }} />
                        </IconButton>
                    </div>
                    <div className={classes.mobilePhoneNumber}>
                        <Typography variant='h6'>
                            Prodaja od investitora : {header.phoneNumber}
                        </Typography>
                    </div>

                    {/*                     
                    <div className={classes.logoMobile}>
                        <img
                            src={`${process.env.REACT_APP_UPLOADS_PATH}/logo/logo.png`}
                            alt="Logo"
                            width={319}
                            height={94}
                            style={{ marginTop: '10px' }} />
                    </div>
                    <div className={classes.phoneAndMenu}>
                        <Typography variant='h6' style={{ color: 'white' }}>
                            Prodaja od investitora : {header.phoneNumber}
                        </Typography>
                        <IconButton size='medium' onClick={() => { setShowModal(true); }}>
                            <MenuIcon fontSize='medium' style={{ color: 'white' }} />
                        </IconButton>
                    </div> */}
                </Toolbar>
            </AppBar>
            <div
                style={{
                    display: showModal ? 'flex' : 'none'
                }}
                onClick={() => { setShowModal(false) }}
                className={classes.modal}>
                <Typography
                    onClick={() => {
                        navigate('/');
                        localStorage.setItem('currentDisplay', JSON.stringify(0));
                        setCurrentDisplay(0);
                    }}
                    variant='h3'
                    style={{ color: 'white', marginBottom: '15px', marginLeft: '20px' }}>
                    Kompleks
                </Typography>
                <Typography
                    onClick={() => {
                        navigate('/');
                        localStorage.setItem('currentDisplay', JSON.stringify(4));
                        setCurrentDisplay(4);
                    }}
                    variant='h3'
                    style={{ color: 'white', marginBottom: '15px', marginLeft: '20px' }}>
                    Tabela kompleksa
                </Typography>
            </div>
        </>
    )
}

export default NavigationBar;