import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './style';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { Alert, AlertTitle } from '@mui/material';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { CircularProgress, Typography, Button } from '@mui/material';
import { getLevelPlanPhotoAction } from '../actions/levels_action';
import { GlobalContext } from '../App';
import compass from '../assets/photos/compass.svg';

const LevelDisplay = () => {

    const { setCurrentDisplay } = useContext(GlobalContext);

    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    const [searchParams, setSearchParams] = useSearchParams();
    const levelId = searchParams.get('levelId');
    const buildingId = searchParams.get('buildingId');

    const [showApartmentDetails, setShowApartmentDetails] = React.useState(null);

    const [planPhoto, setPlanPhoto] = React.useState(null);

    const levelPlanPhotoSelector = useSelector(state => state.levelPlanPhotoState);
    const { levelPhotoPlan } = levelPlanPhotoSelector;

    const apartmentsForLevelSelector = useSelector(state => state.apartmentsForLevelState);
    const { loading, apartmentsForLevel } = apartmentsForLevelSelector;

    const findCenterOfPolygon = (polygon) => {

        const frac_1 = 1 / 2;

        let a_loop = 0;

        for (let i = 0; i < polygon.length - 1; i++) {
            a_loop += (polygon[i].x * polygon[i + 1].y - polygon[i + 1].x * polygon[i].y);
        };

        const A = frac_1 * a_loop;

        const jedan_kroz_6_a = 1 / (6 * A);

        let sum_x = 0;

        for (let i = 0; i < polygon.length - 1; i++) {
            sum_x += (polygon[i].x + polygon[i + 1].x) * (polygon[i].x * polygon[i + 1].y - polygon[i + 1].x * polygon[i].y)
        };

        const Cx = jedan_kroz_6_a * sum_x;

        let sum_y = 0;

        for (let i = 0; i < polygon.length - 1; i++) {
            sum_y += (polygon[i].y + polygon[i + 1].y) * (polygon[i].x * polygon[i + 1].y - polygon[i + 1].x * polygon[i].y)
        };

        const Cy = jedan_kroz_6_a * sum_y;

        return [Cx, Cy];
    };

    React.useEffect(() => {
        if (levelId) {
            dispatch(getLevelPlanPhotoAction(levelId));
        } else if (!levelId || !buildingId) {
            navigate('/');
            localStorage.setItem('currentDisplay', JSON.stringify(0));
            setCurrentDisplay(0);
        }
    }, [levelId]);

    React.useEffect(() => {
        if (typeof levelPhotoPlan !== 'undefined') {
            if (levelPhotoPlan.success) {
                setPlanPhoto(levelPhotoPlan.photoPlan);
                //dispatch(getApartmentsForLevelAction(buildingId, levelId));
            }
        }
    }, [levelPhotoPlan]);

    const getPolygonString = (polygon) => {
        let polygonString = '';
        for (let i = 0; i < polygon.length; i++) {
            const temp = polygon[i].x.toString() + ',' + polygon[i].y.toString() + ' ';
            polygonString += temp;
        };
        return polygonString;
    };

    const ShowLoading = () => {
        return (
            <div className={classes.loading}>
                <CircularProgress color='warning' thickness={4} size={300} />
            </div>
        )
    };

    const ShowError = () => {
        return (
            <div className={classes.error}>
                <Alert severity="error">
                    <AlertTitle>Greška</AlertTitle>
                    Neuspješno prikazivanje stanova
                </Alert>
            </div>
        )
    };

    const getApartmentDetails = (id) => {
        return apartmentsForLevel.apartments.find(apt => apt.id === id);
    };

    const getNumOfRooms = (type) => {
        switch (type) {
            case 'studio': return 'Garsonjera';
            case 'one_room': return 'Jednosoban';
            case 'two_rooms': return 'Dvosoban';
            case 'three_rooms': return 'Trosoban';
            case 'four_rooms': return 'Cetvorosoban';
            case 'five_rooms': return 'Petosoban';
            default: return 0;
        }
    };

    // React.useEffect(()=>{
    //     if(showApartmentDetails) console.log(showApartmentDetails);
    // },[showApartmentDetails])

    const SvgPolygon = () => {
        return (
            <svg
                viewBox={isMobile ? "2 2 1133 640" : "0 0 1133 643"}
                className={classes.svg}
            >
                {
                    apartmentsForLevel.apartments.map((apartment, index) => {
                        return (
                            <polygon
                                onClick={() => {
                                    navigate(`?apartmentId=${apartment.id}`);
                                    localStorage.setItem('currentDisplay', JSON.stringify(3));
                                    setCurrentDisplay(3);
                                }}
                                onMouseEnter={() => {
                                    if (!isMobile) setShowApartmentDetails(apartment.id);
                                    else return;
                                }}
                                onMouseLeave={() => {
                                    if (!isMobile) setShowApartmentDetails(null);
                                }}
                                className={classes.polygon}
                                key={index}
                                points={getPolygonString(apartment.polygon)}
                                style={{ fill: !apartment.status ? 'rgba(129,215,66,0.3)' : 'rgba(255, 0, 0, 0.3)', strokeWidth: '1' }}
                            />
                        )
                    })
                }

                ({
                    apartmentsForLevel.apartments.map((apartment, index) => {
                        return (
                            apartment.status
                                ?
                                <text
                                    key={index}
                                    className={classes.textSvg}
                                    onClick={() => {
                                        navigate(`?apartmentId=${apartment.id}`);
                                        localStorage.setItem('currentDisplay', JSON.stringify(3));
                                        setCurrentDisplay(3);
                                    }}
                                    onMouseEnter={() => {
                                        if (!isMobile) setShowApartmentDetails(apartment.id);
                                        else return;
                                    }}
                                    onMouseLeave={() => {
                                        if (!isMobile) setShowApartmentDetails(null);
                                    }}
                                    x={findCenterOfPolygon(apartment.polygon)[0]}
                                    y={findCenterOfPolygon(apartment.polygon)[1]}
                                    color='white'
                                    fontSize={32}
                                    dominant-baseline="middle"
                                    text-anchor="middle"
                                >
                                    PRODAT
                                </text>
                                :
                                null
                        )
                    })
                })

            </svg>
        );
    };

    const BuildingAndLevel = () => {
        return (
            <>
                {
                    !isMobile
                        ?
                        <div className={classes.buildingAndLevel}>
                            <div className={classes.buildingAndLevelSubDiv}>
                                <Typography variant='h4'>{apartmentsForLevel.buildingName}</Typography>
                                <Typography variant='subtitle1'>Zgrada</Typography>
                            </div>
                            <div className={classes.buildingAndLevelSubDiv}>
                                <Typography variant='h4'>{apartmentsForLevel.levelNum}</Typography>
                                <Typography variant='subtitle1'>Sprat</Typography>
                            </div>
                        </div>
                        :
                        <div className={classes.buildingAndLevelMobile}>
                            <div className={classes.buildingAndLevelSubDivMobile}>
                                <Typography variant='h5'>{apartmentsForLevel.buildingName}</Typography>
                                <Typography variant='subtitle2'>Zgrada</Typography>
                            </div>
                            <div className={classes.buildingAndLevelSubDivMobile}>
                                <Typography variant='h5'>{apartmentsForLevel.levelNum}</Typography>
                                <Typography variant='subtitle2'>Sprat</Typography>
                            </div>
                        </div>
                }
            </>
        );
    };

    const ApartmentDetails = () => {
        return (
            <>
                {
                    showApartmentDetails
                        ?
                        <div
                            style={{ display: 'flex' }}
                            className={classes.apartmentDetails}>
                            <div className={classes.apartmentDetailsCard}>
                                <Typography variant='h3' >{getApartmentDetails(showApartmentDetails)?.number}</Typography>
                                <Typography variant='subtitle' >Broj</Typography>
                            </div>
                            <div className={classes.apartmentDetailsCard}>
                                <Typography variant='h3' >Tip</Typography>
                                <Typography variant='subtitle' >{getNumOfRooms(getApartmentDetails(showApartmentDetails)?.typeOfApartment)}</Typography>
                            </div>
                            <div className={classes.apartmentDetailsCard}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Typography variant='h3' >{+getApartmentDetails(showApartmentDetails)?.area}</Typography>
                                    <Typography variant='h5'>&nbsp; &#13217;</Typography>
                                </div>
                                <Typography variant='subtitle' >Površina</Typography>
                            </div>
                            <div className={classes.apartmentDetailsCard}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Typography variant='h3' >{+getApartmentDetails(showApartmentDetails)?.price}</Typography>
                                    <Typography variant='h5'>&nbsp; &euro;</Typography>
                                </div>
                                <Typography variant='subtitle' >Cijena</Typography>
                            </div>
                            <div className={classes.apartmentDetailsCard}>
                                <img src={compass} width={60} style={{ marginBottom: '5px' }} />
                                <Typography variant='subtitle' >
                                    {getApartmentDetails(showApartmentDetails)?.orientation}
                                </Typography>
                            </div>
                        </div>
                        :
                        null
                }
            </>
        );
    };

    return (
        <>
            {
                planPhoto
                    ?
                    <div
                        style={{ backgroundImage: `url(${process.env.REACT_APP_UPLOADS_PATH + planPhoto})` }}
                        className={classes.levelDisplayDiv}>
                        {
                            loading
                                ?
                                <ShowLoading />
                                :
                                null
                        }
                        {
                            typeof apartmentsForLevel !== 'undefined'
                                ?
                                apartmentsForLevel.success
                                    ?
                                    [
                                        <SvgPolygon key={1} />,
                                        <BuildingAndLevel key={2} />,
                                        <ApartmentDetails key={3} />,
                                        <div className={classes.backDiv} key={4}>
                                            <Button
                                                onClick={() => {
                                                    navigate(`?buildingId=${buildingId}`);
                                                    localStorage.setItem('currentDisplay', JSON.stringify(1));
                                                    setCurrentDisplay(1);
                                                }}
                                                style={{ backgroundColor: 'black', color: 'white' }}
                                                variant='contained'
                                                fullWidth
                                                size={isMobile ? 'small' : 'large'}
                                                disableElevation>
                                                {isMobile ? 'nazad' : 'nazad na zgradu'}
                                            </Button>
                                        </div>
                                    ]
                                    :
                                    <ShowError />
                                :
                                null
                        }
                    </div>
                    :
                    <CircularProgress thickness={5} color='warning' />
            }
        </>
    )
}

export default LevelDisplay