import React from 'react';
import useStyles from './style';
import { AlertTitle, Alert } from '@mui/material';
import { DialogTitle, Dialog, DialogContent, DialogActions, Button, TextField, LinearProgress, Typography } from '@mui/material';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { sendPlanToEmailAction } from '../actions/apartments_action';

const SendToEmailDialog = ({ open, setOpen, apartmentId, buildingName, levelNumber }) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const [email, setEmail] = React.useState('');

    const handleClose = () => {
        setOpen(false);
    };

    const sendPlanToEmailSelector = useSelector(state => state.sendPlanToEmailState);
    const { loading, sendPlanToEmail } = sendPlanToEmailSelector;

    const handleSendEmail = (e) => {
        e.preventDefault();
        dispatch(sendPlanToEmailAction({
            email,
            apartmentId,
            buildingName,
            levelNumber
        }));
    };

    const ShowLoading = () => {
        return (
            <div>
                <Typography variant='caption'>Slanje...</Typography>
                <LinearProgress color='primary' />
            </div>
        )
    };

    const ShowError = () => {
        return (
            <div style={{ marginBottom: '10px' }}>
                <Alert severity="error">
                    <AlertTitle>Greška</AlertTitle>
                    Neuspješno slanje
                </Alert>
            </div>
        )
    };

    const ShowSuccess = () => {
        return (
            <div style={{ marginBottom: '10px' }}>
                <Alert severity="success">
                    <AlertTitle>Uspješno</AlertTitle>
                    Plan stana poslat na uneseni email
                </Alert>
            </div>
        )
    };

    return (
        <Dialog
            maxWidth='sm'
            fullWidth
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>
                Posaljite tlocrt stana na svoj email
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSendEmail} style={{ padding: '5px' }}>
                    {
                        typeof sendPlanToEmail !== 'undefined'
                            ?
                            sendPlanToEmail.success
                                ?
                                <ShowSuccess />
                                :
                                <ShowError />
                            :
                            null
                    }
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                label="Unesite email"
                                name="email"
                                type="email"
                                value={email}
                                onChange={(e) => { setEmail(e.target.value); }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            {
                                loading
                                    ?
                                    <ShowLoading />
                                    :
                                    <Button type='submit' variant='contained' color='success' >
                                        Posalji
                                    </Button>
                            }
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color='error'>
                    Zatvori
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SendToEmailDialog;